import React, { ReactElement, useEffect, useRef, useState } from 'react';
import DataMapperState from '../DataMapperState';
import { FunState } from '@fun-land/fun-state';
import styles from './DataSourceDropdown.module.css';
import { Messages } from '../codegen/Localize';
import classNames from 'classnames';
import { DatasourceCanUploadIcon } from '@tableau/icons/DatasourceCanUploadIcon';
import { selectDataSource } from '../Utilities/selectDataSource';
import { DatasourceCanBaseIcon } from '@tableau/icons/DatasourceCanBaseIcon';
import { NotificationCheckIcon } from '@tableau/icons/NotificationCheckIcon';
import { ArrowTriangleDownIcon } from '@tableau/icons/ArrowTriangleDownIcon';
import { postMessage } from '../FrameMessaging';
import { useClickOutside } from '../Utilities/useClickOutside';

const DataSourceDropdown = ({ state }: { state: FunState<DataMapperState> }): ReactElement | null => {
  const firstSource = useRef<HTMLButtonElement>(null);
  const { targetDatasources, dataSource, overlayDisplay } = state.get();
  const [isOpen, setIsOpen] = useState(false);
  const picker = useClickOutside<HTMLDivElement>((): void => setIsOpen(false));

  useEffect(() => {
    if (firstSource.current) firstSource.current.focus();
  }, [firstSource.current]);

  return (
    <>
      <h2 className={styles.columnHeader}>
        <span className={styles.preHeader}>{Messages.dataSourceHeader()}</span>
        <button
          onClick={(e): void => {
            setIsOpen(!isOpen);
            e.stopPropagation();
            e.preventDefault();
          }}
          className={styles.dropdownTrigger}
          disabled={overlayDisplay.kind !== 'none'}
        >
          <DatasourceCanBaseIcon size={14} className={styles.dbIcon} />{' '}
          <span>{dataSource.caption || dataSource.datasourceName}</span>
          <ArrowTriangleDownIcon size={12} className={styles.downArrow} />
        </button>
      </h2>
      {isOpen && (
        <div className={styles.dataSourceDropdown} ref={picker}>
          <div className={styles.sourceList}>
            {targetDatasources.map((source, i) => {
              const isCurrent = source.datasourceName === dataSource.datasourceName;
              const onClick = (): void => {
                selectDataSource(state)(source.datasourceName);
                setIsOpen(false);
              };
              return (
                <button
                  ref={i ? null : firstSource}
                  onClick={onClick}
                  key={source.datasourceName}
                  className={classNames(styles.dataSource, isCurrent && styles.currentDatasource)}
                >
                  {isCurrent && <NotificationCheckIcon size={12} />}
                  <span>{source.dataSourceCaption ?? source.datasourceName}</span>
                </button>
              );
            })}
            <div className={styles.spacer}></div>
            <button
              className={classNames(styles.dataSource, styles.addDataSource)}
              onClick={(): void => {
                postMessage('invoke_choose_data_source', undefined);
                setIsOpen(false);
              }}
            >
              <DatasourceCanUploadIcon size={14} /> <span>{Messages.addNewDataSource()}</span>
            </button>{' '}
          </div>
        </div>
      )}
    </>
  );
};

export default DataSourceDropdown;
