import { DataSource } from '../../Types/DataSource';
import { Table } from '../../Types/Table';

const mockDataSource: DataSource = {
  fieldsSetup: true,
  datasourceName: 'fullMock',
  caption: 'Primary Mock DataSource',
  tables: [
    {
      tableName: 'Generic Table',
      fields: [
        {
          fullyQualifiedName: 'Table_1-bool',
          fieldName: 'Are we there yet',
          dataType: 'Boolean',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'Table_1-Flavor',
          fieldName: 'Flavor',
          dataType: 'String',
          description:
            'This is a dummy field klsajkljle jtlktjkej altkwjetlket jwekltjweltk wjtlkwejkljekl wejklewj wklejw kl',
          sampleValues: ['Chocolate', 'Vanilla', 'Strawberry'],
        },
        {
          fullyQualifiedName: 'listSample',
          fieldName: 'Product Name',
          dataType: 'String',
          description: 'This is a dummy field',
          sampleValues:
            '<p><b><u>Domain (5 of 198 members)</u></b></p><div style="margin-left:7px"><table><tr><td style="padding-right:4px">Product 1</td></tr><tr><td style="padding-right:4px">Product 10</td></tr><tr><td style="padding-right:4px">Product 100</td></tr><tr><td style="padding-right:4px">Product 101</td></tr><tr><td style="padding-right:4px">Product 102</td></tr></table></div>',
        },
        {
          fullyQualifiedName: 'Table_1-Date',
          fieldName: 'Order Date',
          dataType: 'Date',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'rangeSample',
          fieldName: 'Weight in grams',
          dataType: 'Real',
          description: 'This is a dummy field',
          sampleValues: '<p><b><u>Domain</u></b></p><p style="margin-left:7px">0 to 12,593.4405058</p><p/>',
        },
        {
          fullyQualifiedName: 'Table_1-Date & Time',
          fieldName: 'Date & Time',
          dataType: 'DateTime',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'Table_1-made up thing>',
          fieldName: 'Made up thing',
          dataType: 'Integer',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'Table_1-eclairs',
          fieldName: 'Éclair Integer',
          dataType: 'Integer',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'Table_1-feet',
          fieldName: 'Foot Integer',
          dataType: 'Integer',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'Table_1-there',
          fieldName: 'That place over there',
          dataType: 'String',
          geographicRole: 'City',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'Table_1-altitude',
          fieldName: 'Altitude',
          dataType: 'Real',
          description: 'This is a dummy field',
        },
      ],
    },
    {
      tableName: 'Another Generic Table',
      fields: [
        {
          fullyQualifiedName: 'Table_2-Name',
          fieldName: 'Name',
          dataType: 'String',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'Table_2-Amount_Owed_in_cents',
          fieldName: 'Amount Owed (in cents)',
          dataType: 'Integer',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'Table_2-Height in inches',
          fieldName: 'Height in inches',
          dataType: 'Integer',
          description: 'This is a dummy field',
        },
      ],
    },
    {
      tableName: 'A very specific Table',
      fields: [
        {
          fullyQualifiedName: 'Table_3-Title',
          fieldName: 'Title',
          dataType: 'String',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'Table_3-Genre',
          fieldName: 'Genre',
          dataType: 'String',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'Table_3-Director',
          fieldName: 'Director',
          dataType: 'String',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'Table_3-Run time',
          fieldName: 'Run time',
          dataType: 'Integer',
          description: 'This is a dummy field',
        },
        {
          fieldName: 'Nation',
          fullyQualifiedName: 'Nation',
          dataType: 'String',
          geographicRole: '[Country].[Name]',
          description: 'This is a dummy field',
        },
      ],
    },
    {
      tableName: 'Simply Geographical',
      fields: [
        {
          fullyQualifiedName: 'Table_G-City',
          fieldName: 'City',
          dataType: 'String',
          description: 'This is a dummy field',
          geographicRole: 'City',
        },
        {
          fullyQualifiedName: 'Table_G-State',
          fieldName: 'State',
          dataType: 'String',
          description: 'This is a dummy field',
          geographicRole: 'State',
        },
        {
          fullyQualifiedName: 'Table_G-Director',
          fieldName: 'City Name',
          dataType: 'String',
          geographicRole: 'City',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'Table_G-Airport',
          fieldName: 'Airport',
          dataType: 'String',
          description: `This is a very long dummy field that goes on and on so that 
          I can see what a very, very long description looks like`,
          geographicRole: '[Airport].[Name]',
        },
        {
          fullyQualifiedName: 'Table_G-Long',
          fieldName: 'Longitude',
          dataType: 'Real',
          description: `This is a very long dummy field that goes on and on so that 
          I can see what a very, very long description looks like`,
          geographicRole: '[Geographical].[Longitude]',
        },
      ],
    },
  ],
};

const substituteTable: Table = {
  tableName: 'Generic Table',
  fields: [
    {
      fullyQualifiedName: 'Table_1-Flavor',
      fieldName: 'Flavor',
      dataType: 'String',
      description: 'This is a dummy field',
      sampleValues: ['Chocolate', 'Vanilla', 'Strawberry'],
    },
    {
      fullyQualifiedName: 'Table_1-Color',
      fieldName: 'Color',
      dataType: 'Integer',
      description: 'This is a dummy field',
    },
    {
      fullyQualifiedName: 'Table_1-Date',
      fieldName: 'Date',
      dataType: 'DateTime',
      description: 'This is a dummy field',
    },
    {
      fullyQualifiedName: 'Table_1-Weight in grams',
      fieldName: 'Weight in grams',
      dataType: 'Real',
      description: 'This is a dummy field',
    },
    {
      fullyQualifiedName: 'Table_1-Meeting Time',
      fieldName: 'Meeting Date',
      dataType: 'Date',
      description: 'This is a dummy field',
    },
  ],
};

const anotherMockDataSource: DataSource = {
  fieldsSetup: true,
  datasourceName: 'anotherMockDataSource',
  caption: 'Second DataSource',
  tables: [
    {
      tableName: 'Another Generic Table',
      fields: [
        {
          fullyQualifiedName: 'secondary-Table_2-Name',
          fieldName: 'Name',
          dataType: 'String',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'secondary-Table_2-Amount_Owed_in_cents',
          fieldName: 'Amount Owed (in cents)',
          dataType: 'Integer',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'secondary-Table_2-History',
          fieldName: 'Is Admin',
          dataType: 'Boolean',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'secondary-Table_2-Height in inches',
          fieldName: 'Height in inches',
          dataType: 'Integer',
          description: 'This is a dummy field',
        },
      ],
    },
    {
      tableName: 'Generic Table',
      fields: [
        {
          fullyQualifiedName: 'secondary-Table_1-Flavor',
          fieldName: 'Flavor',
          dataType: 'String',
          description:
            'This is a dummy field klsajkljle jtlktjkej altkwjetlket jwekltjweltk wjtlkwejkljekl wejklewj wklejw kl',
          sampleValues: ['Chocolate', 'Vanilla', 'Strawberry'],
        },
        {
          fullyQualifiedName: 'secondary-Table_1-Color',
          fieldName: 'Color',
          dataType: 'String',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'secondary-Table_1-Date',
          fieldName: 'Date',
          dataType: 'Date',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'secondary-Table_1-Weight in grams',
          fieldName: 'Weight in grams',
          dataType: 'Real',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'secondary-Table_1-Date & Time',
          fieldName: 'Date & Time',
          dataType: 'DateTime',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'secondary-Table_1-made up thing>',
          fieldName: 'Made up thing',
          dataType: 'Integer',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'secondary-Table_1-eclairs',
          fieldName: 'Éclair Integer',
          dataType: 'Integer',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'secondary-Table_1-feet',
          fieldName: 'Foot Integer',
          dataType: 'Integer',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'secondary-Table_1-bool',
          fieldName: 'Are we there yet',
          dataType: 'Boolean',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'secondary-Table_1-there',
          fieldName: 'That place over there',
          dataType: 'String',
          geographicRole: 'City',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'secondary-Table_1-altitude',
          fieldName: 'Altitude',
          dataType: 'Real',
          description: 'This is a dummy field',
        },
      ],
    },
    {
      tableName: 'A very specific Table',
      fields: [
        {
          fullyQualifiedName: 'secondary-Table_3-Title',
          fieldName: 'Encoding',
          dataType: 'String',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'secondary-Table_3-Genre',
          fieldName: 'Flavor',
          dataType: 'String',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'secondary-Table_3-Director',
          fieldName: 'Director',
          dataType: 'String',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'secondary-Table_3-Run time',
          fieldName: 'Run time',
          dataType: 'Integer',
          description: 'This is a dummy field',
        },
      ],
    },
    {
      tableName: 'Simply Geographical',
      fields: [
        {
          fullyQualifiedName: 'secondary-Table_G-City',
          fieldName: 'City',
          dataType: 'String',
          description: 'This is a dummy field',
          geographicRole: 'City',
        },
        {
          fullyQualifiedName: 'secondary-Table_G-State',
          fieldName: 'State',
          dataType: 'String',
          description: 'This is a dummy field',
          geographicRole: 'State',
        },
        {
          fullyQualifiedName: 'secondary-Table_G-Director',
          fieldName: 'City Name',
          dataType: 'String',
          geographicRole: '[City].[Name]',
          description: 'This is a dummy field',
        },
        {
          fullyQualifiedName: 'secondary-Table_G-Airport',
          fieldName: 'Airport',
          dataType: 'String',
          description: `This is a very long dummy field that goes on and on so that 
          I can see what a very, very long description looks like`,
          geographicRole: '[Airport].[Name]',
        },
        {
          fullyQualifiedName: 'secondary-Table_G-Long',
          fieldName: 'Longitude',
          dataType: 'Real',
          description: `This is a very long dummy field that goes on and on so that 
          I can see what a very, very long description looks like`,
          geographicRole: '[Geographical].[Longitude]',
        },
      ],
    },
  ],
};

const linkfieldsToDatasource = (datasource: DataSource): DataSource => {
  const result = {
    ...datasource,
    tables: datasource.tables.map((table) => ({
      ...table,
      fields: table.fields.map((field) => ({
        ...field,
        fullyQualifiedName: `[${datasource.datasourceName}].${field.fullyQualifiedName}`,
      })),
    })),
  };
  return result;
};

export const dataSources: Record<string, DataSource> = {
  fullMock: linkfieldsToDatasource(mockDataSource),
  anotherMockDataSource: linkfieldsToDatasource(anotherMockDataSource),
  modded: linkfieldsToDatasource({
    ...mockDataSource,
    datasourceName: 'Modded',
    caption: 'Modded primary',
    tables: [substituteTable, ...mockDataSource.tables.slice(1)],
  }),
  loner: linkfieldsToDatasource({
    ...mockDataSource,
    datasourceName: 'loner',
    caption: 'Single Table Data With an annoyingly and unnecessarily long name',
    tables: [mockDataSource.tables[0]],
  }),
  empty: linkfieldsToDatasource({
    ...mockDataSource,
    datasourceName: 'empty',
    caption: 'Empty Data Source',
    tables: [],
  }),
  addLater: linkfieldsToDatasource({
    ...mockDataSource,
    datasourceName: 'addLater',
    caption: 'A data source added later',
    tables: [mockDataSource.tables[0]],
  }),
  dummy: linkfieldsToDatasource({
    ...mockDataSource,
    datasourceName: 'dummy',
    caption: 'Dummy, do not pick',
    tables: [mockDataSource.tables[0]],
  }),
  dummy2: linkfieldsToDatasource({
    ...mockDataSource,
    datasourceName: 'dummy2',
    caption: 'Dummy 2, do not pick',
    tables: [mockDataSource.tables[0]],
  }),
  dummy3: linkfieldsToDatasource({
    ...mockDataSource,
    datasourceName: 'dummy3',
    caption: 'Dummy 3, do not pick',
    tables: [mockDataSource.tables[0]],
  }),
  dummy4: linkfieldsToDatasource({
    ...mockDataSource,
    datasourceName: 'dummy4',
    caption: 'Dummy 4, do not pick',
    tables: [mockDataSource.tables[0]],
  }),
  dummy5: linkfieldsToDatasource({
    ...mockDataSource,
    datasourceName: 'dummy5',
    caption: 'Dummy 5, do not pick',
    tables: [mockDataSource.tables[0]],
  }),
};

export default mockDataSource;
export { substituteTable, anotherMockDataSource };

