/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { AcceleratorField } from '../Types/AcceleratorField';
import { Field } from '../Types/Field';

const autoMap = (acceleratorFields: AcceleratorField[], dataSourceFields: Field[]): AcceleratorField[] =>
  acceleratorFields.map((field) => {
    if (field.newTargetFieldMapping) return field;
    const match =
      dataSourceFields.find(
        (f) =>
          f.fieldName === field.fieldName && f.dataType === field.dataType && f.geographicRole === field.geographicRole,
      )?.fullyQualifiedName ?? '';
    return match ? { ...field, newTargetFieldMapping: match, status: 'pending' } : field;
  });

export default autoMap;
