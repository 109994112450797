/* eslint-disable @typescript-eslint/no-explicit-any */
import { DataType, GeographicRole } from '../Types/DataType';
import { Field } from '../Types/Field';
import { Table } from '../Types/Table';
import isEligibleField from './isEligibleField';

interface LocalizeMessage {
  _locale: string; // MessageLocale;
}

interface Localize {
  msg: LocalizeMessage;
  format: any;
  message: any;
}

declare global {
  interface Window {
    Localize: Localize;
  }
}
const locale: string = window?.Localize?.msg?._locale ?? 'en';

const fieldSorter =
  (allowedType: DataType, allowedGeo?: GeographicRole) =>
  (a: Field, b: Field): number => {
    const isEligible = isEligibleField(allowedType, allowedGeo);
    if (isEligible(a) && !isEligible(b)) return -1;
    if (!isEligible(a) && isEligible(b)) return 1;
    return a.fieldName.localeCompare(b.fieldName, locale);
  };

const tableSorter = (a: Table, b: Table): number => {
  if (a.tableName > b.tableName) return 1;
  if (a.tableName < b.tableName) return -1;
  return 0;
};

export { fieldSorter, tableSorter };
