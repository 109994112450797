import React, { ReactElement } from 'react';
import './Alert.css';
import { NotificationInfoOutlineIcon } from '@tableau/icons/NotificationInfoOutlineIcon';

const Alert = ({ text }: { text: string }): ReactElement => (
  <div className="alert">
    <NotificationInfoOutlineIcon size={14} />
    <div>{text}</div>
  </div>
);

export default Alert;
