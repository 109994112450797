interface DataSourceIdentifier {
  name: string;
  caption: string;
}

interface MappedFieldPair {
  targetFullyQualifiedFieldName: string;
  sourceFullyQualifiedFieldName: string;
}

interface TargetFieldMapping {
  fields: MappedFieldPair[];
}

export interface SaveableState {
  selectedSourceDataSource: DataSourceIdentifier;
  selectedTargetDataSource: DataSourceIdentifier;
  targetFields: TargetFieldMapping;
}

export const isValidSavedSession = ({
  selectedSourceDataSource,
  selectedTargetDataSource,
  targetFields,
}: SaveableState): boolean => {
  let valid: boolean = !!selectedSourceDataSource?.name && !!selectedTargetDataSource?.name;
  valid =
    valid &&
    !targetFields.fields.find((field) => !field.sourceFullyQualifiedFieldName || !field.targetFullyQualifiedFieldName);
  return valid;
};
