import { append } from '@fun-land/accessor';
import { FunState } from '@fun-land/fun-state';
import DataMapperState from '../DataMapperState';
import { TargetDataSource } from '../Types/DataSource';

const addTargetDatasource = (datasource: TargetDataSource, state: FunState<DataMapperState>): void => {
  const { targetDatasources } = state.get();
  if (!targetDatasources.find((s) => s.datasourceName === datasource.datasourceName)) {
    state.prop('targetDatasources').mod(
      append<TargetDataSource>({
        dataSourceCaption: datasource.dataSourceCaption,
        datasourceName: datasource.datasourceName,
      }),
    );
  }
};

export default addTargetDatasource;
