import React, { ReactElement, useEffect, useRef } from 'react';
import { matchesStatus, ReturnFields } from '../Types/AcceleratorField';
import AcceleratorFieldComponent from '../Components/AcceleratorFieldComponent';
import DataSourceFieldPicker from '../Components/DataSourceFieldPicker';
import DataMapperState from '../DataMapperState';
import { Messages } from '../codegen/Localize';
import { FunState, merge } from '@fun-land/fun-state';
import styles from './Mapper.module.css';
import StatusIcon from '../Components/StatusIcon';
import Button from '../Components/Button';
import classNames from 'classnames';
import { ScrollContainer } from '../Components/ScrollContainer';
import DataSourceDropdown from '../Components/DataSourceDropdown';

interface MapperProps {
  state: FunState<DataMapperState>;
}

const prepAcceleratorFields = (state: DataMapperState): ReturnFields => ({
  sourceDatasourceName: state.acceleratorDataSourceName ?? '',
  targetDatasourceName: state.dataSource.datasourceName ?? '',
  replaceDataSourceFieldsMap: state.acceleratorFields.filter(matchesStatus('pending')).map((field) => ({
    sourceFieldName: field.fullyQualifiedName ?? '',
    targetFieldName: field.newTargetFieldMapping ?? '',
  })),
});

const MapData = ({ state }: MapperProps): ReactElement => {
  const {
    dataSource,
    acceleratorFields: sourceFields,
    activeTargetField,
    version,
    overlayDisplay,
    acceleratorDataSourceCaption,
  } = state.get();
  const showOverlay = overlayDisplay.kind !== 'none';
  const scrollContainer = useRef<HTMLDivElement>(null);
  const unmappedFieldCount = sourceFields.filter(
    (field) => field.status !== 'pending' && field.status !== 'mapped' && field.status !== 'locked',
  ).length;

  useEffect(() => {
    if (scrollContainer.current) {
      // Scroll to top when dataSource changes
      setTimeout((): void => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollTo(0, 0);
        }
      }, 10);
    }
  }, [dataSource]);
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const progressStyle = {
    '--value': ((sourceFields.length - unmappedFieldCount) / sourceFields.length) * 100,
  } as React.CSSProperties;
  return (
    <>
      <div className={classNames(styles.mapper, 'acceleratorHook')}>
        <div className={classNames(styles.fieldGrid, styles.fieldRowHeader)}>
          <h2 className={styles.columnHeader}>
            <span className={styles.preHeader}>{Messages.preTargetHeader()}</span>
            {acceleratorDataSourceCaption || Messages.targetHeader()}
          </h2>
          <div></div>
          {version > 1 && !showOverlay ? (
            <DataSourceDropdown state={state} />
          ) : (
            <h2 className={classNames(styles.columnHeader, version > 1 && styles.v2ColumnHeader)}>
              {dataSource.datasourceName && <span className={styles.preHeader}>{Messages.dataSourceHeader()}</span>}
              {Messages.myDataHeader()}
            </h2>
          )}
        </div>
        <ScrollContainer className={styles.scrollContainer} ref={scrollContainer}>
          <div className={styles.fieldGrid}>
            {sourceFields.map(
              (field): ReactElement => (
                <React.Fragment key={field.fieldName}>
                  <AcceleratorFieldComponent field={field} focusable={!showOverlay} />
                  <div className={classNames(styles.status, field.status)}>
                    <StatusIcon status={field.status} />
                  </div>
                  <div className={styles.iconSelector}>
                    <DataSourceFieldPicker
                      selected={field.newTargetFieldMapping ?? ''}
                      setOpen={(open: boolean): void => {
                        state.prop('activeTargetField').set(open ? field : null);
                      }}
                      open={activeTargetField === field}
                      state={state}
                      targetField={field}
                      focusable={!showOverlay}
                    />
                  </div>
                </React.Fragment>
              ),
            )}
          </div>
        </ScrollContainer>
        <footer className={styles.footer}>
          <div role="progressbar" style={progressStyle} className={`${styles.footerDiv} ${styles.progressBar}`} />
          <div className={styles.unmappedFieldCount}>
            {Messages.status({
              count: `${sourceFields.length - unmappedFieldCount}`,
              total: `${sourceFields.length}`,
            })}
          </div>
          <div>
            <Button
              buttonType="primary"
              onClick={(): void =>
                merge(state)({
                  overlayDisplay: { kind: 'map_data', unmappedFieldCount, mapping: prepAcceleratorFields(state.get()) },
                })
              }
              disabled={
                sourceFields.some(matchesStatus('invalid')) ||
                !sourceFields.some(matchesStatus('pending')) ||
                showOverlay
              }
            >
              {Messages.confirmCTA()}
            </Button>
          </div>
        </footer>
      </div>
    </>
  );
};

export default MapData;
