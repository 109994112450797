import React, { ReactElement, useEffect, useRef } from 'react';
import DataMapperState from '../DataMapperState';
import { FunState } from '@fun-land/fun-state';
import styles from './DataSourcePicker.module.css';
import { Messages } from '../codegen/Localize';
import classNames from 'classnames';
import Button from './Button';
import { connectDataSource } from '../Views/ConnectToData';
import { DatasourceCanUploadIcon } from '@tableau/icons/DatasourceCanUploadIcon';
import { selectDataSource } from '../Utilities/selectDataSource';

const DataSourcePicker = ({ state }: { state: FunState<DataMapperState> }): ReactElement | null => {
  const firstSource = useRef<HTMLButtonElement>(null);
  const { targetDatasources, dataSource, overlayDisplay } = state.get();
  useEffect(() => {
    if (firstSource.current) firstSource.current.focus();
  }, []);

  return (
    <div className={styles.dataSourcePicker}>
      <h2 className={styles.pickerHeader}>{Messages.chooseDatasourceHeader()}</h2>
      <p>{Messages.chooseDatasourceBody()}</p>
      <div className={styles.sourceListWrap}>
        <h3 className={styles.sourceListHeader}>{Messages.connectedDataSourcesHeader()}</h3>
        <div className={styles.sourceList}>
          {targetDatasources.map((source, i) => {
            const isCurrent = source.datasourceName === dataSource.datasourceName;
            const onClick = (): void => selectDataSource(state)(source.datasourceName);
            return (
              <button
                ref={i ? null : firstSource}
                onClick={onClick}
                key={source.datasourceName}
                disabled={overlayDisplay.kind !== 'none'}
                className={classNames(styles.dataSource, isCurrent && styles.currentDatasource)}
              >
                <span>{source.dataSourceCaption ?? source.datasourceName}</span>
              </button>
            );
          })}
        </div>
      </div>
      <Button
        buttonType="primary"
        onClick={(): void => connectDataSource(state)}
        className={styles.addDataSource}
        disabled={overlayDisplay.kind !== 'none'}
      >
        <DatasourceCanUploadIcon size={14} /> {Messages.addNewDataSource()}
      </Button>
    </div>
  );
};

export default DataSourcePicker;
