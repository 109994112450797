import DataMapperState from '../DataMapperState';
import { matchesStatus } from '../Types/AcceleratorField';
import { SaveableState } from '../Types/SaveableState';
import { postMessage } from '../FrameMessaging';

export const getSaveableState = (state: DataMapperState): SaveableState => ({
  selectedSourceDataSource: {
    name: state.acceleratorDataSourceName ?? '',
    caption: state.acceleratorDataSourceCaption,
  },
  selectedTargetDataSource: { name: state.dataSource.datasourceName ?? '', caption: state.dataSource.caption },
  targetFields: {
    fields: state.acceleratorFields.filter(matchesStatus('pending')).map((field) => ({
      sourceFullyQualifiedFieldName: field.fullyQualifiedName ?? '',
      targetFullyQualifiedFieldName: field.newTargetFieldMapping ?? '',
    })),
  },
});

export const saveSession = (state: DataMapperState): void => {
  postMessage('store_current_state', getSaveableState(state));
};
