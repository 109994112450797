import React, { ReactElement } from 'react';
import { postMessage } from '../FrameMessaging';
import DataMapperState, { clearAcceleratorFieldBindings, restorePreviousSession } from '../DataMapperState';
import { Messages } from '../codegen/Localize';
import { Overlay } from '../Views/Overlay';
import { postActionTelemetry } from '../Utilities/postActionTelemetry';
import Button from './Button';
import { FunState, merge } from '@fun-land/fun-state';
import { saveSession } from '../Utilities/getSaveableState';
import addTargetDatasource from '../Utilities/addTargetDatasource';

// eslint-disable-next-line complexity
export const Overlays = ({ state }: { state: FunState<DataMapperState> }): ReactElement | null => {
  const setOverlay = state.prop('overlayDisplay').set;
  const closeOverlay = (): void => setOverlay({ kind: 'none' });
  const { page, overlayDisplay, acceleratorFields } = state.get();
  switch (overlayDisplay.kind) {
    case 'reopen': {
      const isDataMappable: boolean = !!state.prop('dataSource').get().tables.length;
      const related_view = isDataMappable ? 'map_data' : 'connect_to_data';
      return (
        <Overlay
          headerText={Messages.reopenInstructionOverlayHeader()}
          bodyText={Messages.reopenInstructionOverlayBody()}
          footer={
            <Button
              buttonType="primary"
              onClick={(): void => {
                postActionTelemetry('close_dialog', related_view);
                postMessage('close_dialog', { shouldDisable: page === 'replacement_complete' });
              }}
            >
              {Messages.reopenInstructionOverlayBtn()}
            </Button>
          }
        />
      );
    }
    case 'change_datasource': {
      const finish = (): void => {
        const { datasourceName, caption: dataSourceCaption } = overlayDisplay.newDataSource;
        addTargetDatasource({ datasourceName, dataSourceCaption }, state);
        closeOverlay();
      };
      return (
        <Overlay
          headerText={Messages.changeDataSourceOverlayHeader()}
          bodyText={Messages.changeDataSourceOverlayText()}
          footer={
            <>
              <Button
                buttonType="secondary"
                onClick={(): void => {
                  merge(state)({ page: 'mapper' });
                  finish();
                }}
              >
                {Messages.overlayBackButton()}
              </Button>
              <Button
                buttonType="primary"
                onClick={(): void => {
                  merge(state)({ dataSource: overlayDisplay.newDataSource, page: 'data_connected' });
                  state.mod(clearAcceleratorFieldBindings);
                  saveSession(state.get());
                  postMessage('get_data_source_fields', {
                    dataSourceName: overlayDisplay.newDataSource.datasourceName,
                  });
                  postMessage('resize_dialog', { shouldDock: true });
                  finish();
                }}
              >
                {Messages.changeDataSourceOverlayButton()}
              </Button>
            </>
          }
        />
      );
    }
    case 'close':
      return (
        <Overlay
          headerText={Messages.closeDataMapperReplacementCompleteOverlayHeader()}
          bodyText={
            page === 'replacement_complete'
              ? Messages.closeDataMapperReplacementCompleteOverlayBody()
              : Messages.preReplaceOverlayBody()
          }
          footer={
            <>
              <Button buttonType="secondary" onClick={closeOverlay}>
                {Messages.overlayBackButton()}
              </Button>
              <Button buttonType="primary" onClick={(): void => setOverlay({ kind: 'reopen' })}>
                {Messages.closeDataMapperReplacementCompleteOverlayCloseButton()}
              </Button>
            </>
          }
        />
      );
    case 'restorePreviousSession': {
      if (!overlayDisplay.mapperState) return null;
      return (
        <Overlay
          headerText={Messages.restoreSessionHeader()}
          bodyText={Messages.restoreSessionMessage()}
          footer={
            <>
              <Button
                buttonType="secondary"
                onClick={(): void => {
                  saveSession(state.get());
                  closeOverlay();
                }}
              >
                {Messages.restoreSessionCancelButton()}
              </Button>
              <Button
                buttonType="primary"
                onClick={(): void => restorePreviousSession(state, overlayDisplay.mapperState)}
              >
                {Messages.restoreSessionProceedButton()}
              </Button>
            </>
          }
        />
      );
    }
    case 'map_data': {
      const { unmappedFieldCount, mapping } = overlayDisplay;
      return (
        <Overlay
          headerText={Messages.overlayHeader()}
          bodyText={
            unmappedFieldCount
              ? Messages.overlayTextUnmapped({ count: `${unmappedFieldCount}` })
              : Messages.overlayTextMapped()
          }
          footer={
            <>
              <Button
                data-testid="overlay-cancel"
                buttonType="secondary"
                onClick={(): void => {
                  closeOverlay();
                  postActionTelemetry('cancel_replace_data', 'map_data', acceleratorFields.length, unmappedFieldCount);
                }}
              >
                {Messages.overlayBackButton()}
              </Button>
              <Button
                data-testid="overlay-confirm"
                buttonType="primary"
                onClick={(): void => {
                  closeOverlay();
                  postActionTelemetry('replace_data', 'map_data', acceleratorFields.length, unmappedFieldCount);
                  postMessage('apply_data_mapping', mapping);
                }}
              >
                {Messages.overlayReplaceButton()}
              </Button>
            </>
          }
        />
      );
    }
    case 'none':
      return null;
  }
};
