/* eslint-disable complexity */
import { filter } from '@fun-land/accessor';
import { FunState, merge } from '@fun-land/fun-state';
import React, { KeyboardEvent, ReactElement, useEffect, useRef } from 'react';
import { Messages } from '../codegen/Localize';
import DataMapperState, { clearUIActivity } from '../DataMapperState';
import { DataType, GeographicRole } from '../Types/DataType';
import { Field } from '../Types/Field';
import DataSourceFieldComponent from './DataSourceFieldComponent';
import './DataSourceFieldPicker.css';
import TableSection from './TableSection';
import { ClearBaseIcon as ClearFilter } from '@tableau/icons/ClearBaseIcon';
import { AcceleratorField } from '../Types/AcceleratorField';
import { ArrowTriangleDownIcon } from '@tableau/icons/ArrowTriangleDownIcon';
import { fieldSorter } from '../Utilities/sorters';
import isEligibleField from '../Utilities/isEligibleField';
import { getVerticalPosition } from '../Utilities/getVerticalPosition';
import { postMessage } from '../FrameMessaging';
import { getSaveableState } from '../Utilities/getSaveableState';
import DataTypeIcon from './DataTypeIcon';
import classNames from 'classnames';

interface DropDownProps {
  selected?: string;
  setOpen: (open: boolean) => void;
  open?: boolean;
  state: FunState<DataMapperState>;
  targetField: AcceleratorField;
  focusable?: boolean;
}

const newTargetFieldMapper = (state: FunState<DataMapperState>, targetFieldId: string, id: string): void => {
  merge(state.prop('acceleratorFields').focus(filter((field) => field.fullyQualifiedName === targetFieldId)))({
    newTargetFieldMapping: id,
    status: id ? 'pending' : 'unmapped',
    error: '',
  });
  if (state.prop('version').get() > 1) postMessage('store_current_state', getSaveableState(state.get()));
  state.mod(clearUIActivity);
};

const getPickFn =
  (
    field: Field,
    selectable: string[],
    state: FunState<DataMapperState>,
    targetFieldId: string,
    button: HTMLButtonElement | null,
  ) =>
  (): void => {
    if (selectable.includes(field.fullyQualifiedName) && button) {
      newTargetFieldMapper(state, targetFieldId, field.fullyQualifiedName);
      setTimeout((): void => button.focus());
    }
  };

const isSelectableField =
  (allowedType: DataType, filterTerm: string, allowedGeo?: GeographicRole) =>
  (field: Field): boolean =>
    field.fieldName.toLowerCase().search(filterTerm.toLowerCase()) > -1 &&
    field.dataType === allowedType &&
    field.geographicRole === allowedGeo;

const fieldMatches =
  (filterTerm: string) =>
  (field: Field): boolean =>
    field.fieldName.toLowerCase().search(filterTerm.toLowerCase()) > -1;

const fieldFullyQualifiedNameEquals =
  (name: string) =>
  (field: Field): boolean =>
    field.fullyQualifiedName === name;

const DataSourceFieldPicker = ({
  selected,
  setOpen,
  open,
  state,
  targetField,
  focusable = true,
}: DropDownProps): ReactElement => {
  const { filterTerm, highlightedField, zone, dataSource } = state.get();
  const allowedType = targetField.dataType;
  const allowedGeo = targetField.geographicRole;
  const display = open ? filterTerm : selected;
  const pickListRef = useRef<HTMLDivElement>(null);
  const fields = state
    .prop('dataSource')
    .get()
    .tables.flatMap((table) => table.fields.sort(fieldSorter(allowedType, allowedGeo)))
    .filter(fieldMatches(filterTerm));
  const selectableFields = fields
    .filter(isSelectableField(allowedType, filterTerm, allowedGeo))
    .map((field): string => field.fullyQualifiedName);
  const input = useRef<HTMLInputElement>(null);
  const button = useRef<HTMLButtonElement>(null);
  const clickCheck = (e: MouseEvent): void => {
    if (
      pickListRef.current &&
      e.target &&
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      !pickListRef.current.contains(e.target as Node)
    ) {
      close();
    }
  };
  const isEligible = isEligibleField(allowedType, allowedGeo);

  useEffect((): (() => void) => {
    if (open) {
      document.addEventListener('click', clickCheck);
      input.current?.focus();
      if (input.current) state.prop('zone').set(getVerticalPosition(input.current, '.acceleratorHook'));
    } else {
      document.removeEventListener('click', clickCheck);
      merge(state)({
        highlightedField: '',
        filterTerm: '',
      });
      button.current?.focus();
    }
    return (): void => document.removeEventListener('click', clickCheck);
  }, [open]);

  const selectEnd = (pos: number): void => input.current?.setSelectionRange(pos, pos);

  const handleKeyboardSelection = (e: KeyboardEvent): void => {
    if (highlightedField && selectableFields.includes(highlightedField)) {
      e.stopPropagation();
      e.preventDefault();
      newTargetFieldMapper(state, targetField.fullyQualifiedName, highlightedField);
    }
  };

  const keyHandler = (e: KeyboardEvent): void => {
    const currentIndex = fields.findIndex(fieldFullyQualifiedNameEquals(highlightedField));
    switch (e.key) {
      case 'ArrowUp': {
        e.stopPropagation();
        if (currentIndex < 1) {
          state.prop('highlightedField').set('');
          setTimeout((): void => selectEnd(filterTerm.length));
          input.current?.focus();
          return;
        }
        const nextField = fields[Math.max(currentIndex - 1, 0)];
        state.prop('highlightedField').set(nextField.fullyQualifiedName);
        break;
      }
      case 'ArrowDown': {
        e.stopPropagation();
        const nextField = fields[Math.min((currentIndex ?? 0) + 1, fields.length - 1)];
        state.prop('highlightedField').set(nextField.fullyQualifiedName);
        break;
      }
      case ' ': {
        handleKeyboardSelection(e);
        break;
      }
      case 'Enter': {
        handleKeyboardSelection(e);
        break;
      }
      case 'Tab': {
        close();
        break;
      }
      case 'Escape': {
        close();
        break;
      }
    }
  };

  const closedKeyHandler = (e: KeyboardEvent): void => {
    if (e.key in [' ', 'Enter']) {
      input.current?.focus();
      setOpen(true);
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const close = (): void => {
    setOpen(false);
    setTimeout((): void => button.current?.blur());
  };

  const sections = dataSource.tables.map(
    (table): ReactElement => (
      <TableSection tableName={table.tableName} key={table.tableName}>
        {table.fields
          .filter(fieldMatches(filterTerm))
          .sort(fieldSorter(allowedType, allowedGeo))
          .map(
            (field): ReactElement => (
              <DataSourceFieldComponent
                highlighted={open && fieldFullyQualifiedNameEquals(highlightedField)(field)}
                dataType={typeof field === 'string' ? undefined : field.dataType}
                key={field.fullyQualifiedName}
                keyHandler={keyHandler}
                field={field}
                pick={getPickFn(field, selectableFields, state, targetField.fullyQualifiedName, button.current)}
                ineligibleMessage={isEligible(field) ? '' : Messages.ineligibleField({ type: Messages[allowedType]() })}
              />
            ),
          )}
      </TableSection>
    ),
  );

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const selectedField = selected && state.prop('dataSourceFields').get()[selected];
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const { fieldName, dataType, geographicRole } = selectedField || {
    fieldName: Messages.selectField(),
    dataType: null,
    geographicRole: null,
  };
  const clearFunction = filterTerm
    ? (e: React.MouseEvent<HTMLButtonElement>): void => {
        state.prop('filterTerm').set('');
        console.log('Clear Filter');
        e.stopPropagation();
      }
    : (_e: React.MouseEvent<HTMLButtonElement>): void =>
        newTargetFieldMapper(state, targetField.fullyQualifiedName, '');
  return (
    <div className={`dataSourceFieldPicker${open ? ' open' : ''}`} ref={pickListRef} data-testid="fieldPicker">
      <input
        ref={input}
        className="filterTerm"
        type="text"
        value={display}
        onChange={(e): void => state.prop('filterTerm').set(e.target.value)}
        placeholder={Messages.selectField() ?? ''}
        onFocus={(e): void => {
          setOpen(true);
          e.stopPropagation();
        }}
        onKeyDown={keyHandler}
        aria-label={''}
      />
      <ArrowTriangleDownIcon size={14} className="disclosure" />
      <div className={`fieldList zone${zone}`}>
        {fields.length > 0 ? (
          sections
        ) : (
          <button className="dataSourceField empty" disabled={true}>
            {Messages.noResults()}
          </button>
        )}
      </div>
      <button
        className={classNames('dropdownButton', selected && 'mapped')}
        disabled={!focusable}
        ref={button}
        onClick={(e): void => {
          setOpen(true);
          setTimeout((): void => input.current?.focus());
          e.stopPropagation();
        }}
        onKeyDown={closedKeyHandler}
      >
        {fieldName}
        {dataType && <DataTypeIcon name={geographicRole ? 'Geographic' : dataType} size={14} className="dataType" />}
        <ArrowTriangleDownIcon size={14} className="disclosure" />
      </button>
      {(!!targetField.newTargetFieldMapping || (!!filterTerm && open)) && (
        <div>
          <button className="clearField" data-testid={`clearButton`} onClick={(e): void => clearFunction(e)}>
            <ClearFilter size={12} />
            <span>{Messages.clearFieldMapping()}</span>
          </button>
        </div>
      )}
      {targetField.error && <div className="mappingError">{targetField.error}</div>}
    </div>
  );
};

export default DataSourceFieldPicker;
