import React, { ReactElement, useEffect, useRef } from 'react';
import { postMessage } from '../FrameMessaging';
import AcceleratorFieldComponent from '../Components/AcceleratorFieldComponent';
import DataMapperState from '../DataMapperState';
import { Messages } from '../codegen/Localize';
import { FunState, merge } from '@fun-land/fun-state';
import styles from './ConnectToData.module.css';
import { postActionTelemetry } from '../Utilities/postActionTelemetry';
import Button from '../Components/Button';
import classNames from 'classnames';
import { ScrollContainer } from '../Components/ScrollContainer';
import DataSourcePicker from '../Components/DataSourcePicker';
import { DatasourceCanUploadIcon } from '@tableau/icons/DatasourceCanUploadIcon';

interface MapperProps {
  state: FunState<DataMapperState>;
}

export const connectDataSource = (state: FunState<DataMapperState>): void => {
  const { dataSource, dataSourceFields } = state.get();
  if (!dataSource.datasourceName) {
    postMessage('invoke_choose_data_source', undefined);
  } else {
    if (!dataSourceFields.length) {
      postMessage('get_data_source_fields', { dataSourceName: dataSource.datasourceName });
      postMessage('resize_dialog', { shouldDock: true });
      merge(state)({ page: 'data_connected' });
    }
  }
  postActionTelemetry('connect_to_data_source', 'connect_to_data');
};

const ConnectToData = ({ state }: MapperProps): ReactElement => {
  const {
    dataSource,
    acceleratorFields: sourceFields,
    targetDatasources,
    version,
    overlayDisplay,
    acceleratorDataSourceCaption,
  } = state.get();
  const mapperDiv = useRef<HTMLDivElement>(null);
  const scrollContainer = useRef<HTMLDivElement>(null);
  const showOverlay = overlayDisplay.kind !== 'none';

  useEffect(() => {
    if (scrollContainer.current) {
      setTimeout((): void => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollTo(0, 0);
        }
      }, 10);
    }
  }, [dataSource]);

  return (
    <div className={classNames(styles.ConnectToData, 'acceleratorHook')} ref={mapperDiv}>
      <div className={styles.fieldListCol}>
        <h1 className={styles.header}>{acceleratorDataSourceCaption || Messages.sampleDataHeader()}</h1>
        <ScrollContainer className={styles.scrollContainer} ref={scrollContainer}>
          <ul className={styles.fieldList}>
            {sourceFields.map(
              (field): ReactElement => (
                <li className={field.status ? styles[`${field.status}`] : ''} key={field.fieldName}>
                  <AcceleratorFieldComponent field={field} needsData={true} focusable={!showOverlay} />
                </li>
              ),
            )}
          </ul>
        </ScrollContainer>
      </div>
      {targetDatasources.length ? (
        <DataSourcePicker state={state} />
      ) : (
        <div className={styles.noDataSourceConnected}>
          <div className={styles.ctaBorderBox}>
            <div className={styles.cta}>
              <div>
                <h3 className={styles.noDataHeader}>{Messages.noDataHeader()}</h3>
                <p className={styles.noDataText}>{Messages.noDataSubheader()}</p>
              </div>
              <Button buttonType="primary" onClick={(): void => connectDataSource(state)} disabled={showOverlay}>
                {version > 1 && <DatasourceCanUploadIcon size={14} />} {Messages.connectDataSource()}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConnectToData;
