import classNames from 'classnames';
import React, { ReactNode, ReactElement } from 'react';
import styles from './Button.module.css';

type ButtonProps = {
  children: ReactNode,
  buttonType: 'primary' | 'secondary' | 'deemphasized'
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({ children, className, buttonType, ...rest }: ButtonProps): ReactElement =>
  <button {...rest} className={classNames(styles.Button, {
    [styles.secondary]: buttonType === 'secondary',
    [styles.deemphasized]: buttonType === 'deemphasized'
  }, className)} >{children}</button>;

export default Button;
