const versionThresholds = [{ tv: '2023.2', dmv: 2 }];

const _versionCompare = (parts1: string[], parts2: string[]): 0 | 1 | -1 => {
  if (parts1.length === 0 && parts2.length === 0) return 0;
  const [ver1 = '0', ...rest1] = parts1;
  const [ver2 = '0', ...rest2] = parts2;
  const v1 = parseInt(ver1, 10);
  const v2 = parseInt(ver2, 10);
  return v1 > v2 ? 1 : v1 < v2 ? -1 : _versionCompare(rest1, rest2);
};

const versionCompare = (ver1: string, ver2: string): 0 | 1 | -1 => _versionCompare(ver1.split('.'), ver2.split('.'));

export const getVersion = (tableauVersion: string): number => {
  if (tableauVersion === '0.0.0') return versionThresholds.length + 1;
  const versions = [...versionThresholds];
  let v = versions.pop();
  while (v !== undefined) {
    if (versionCompare(tableauVersion, v.tv) > -1) return v.dmv;
    v = versions.pop();
  }
  return 1;
};
