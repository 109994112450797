import React, { ReactElement, useEffect } from 'react';
import './DataMapper.css';
import { postMessage } from './FrameMessaging';
import DockedDialog from './Views/DockedDialog';
import { ViewType } from './Types/ViewTypes';
import DataMapperState, { init_DataMapperState } from './DataMapperState';
import Harness from './Views/Harness';
import useFunState from '@fun-land/use-fun-state';
import { Messages } from './codegen/Localize';
import MapData from './Views/MapData';
import ConnectToData from './Views/ConnectToData';
import { postActionTelemetry } from './Utilities/postActionTelemetry';
import MessageHandler from './Components/MessageHandler';
import focusTrap from './Utilities/focusTrap';
import { Overlays } from './Components/Overlays';
import { getWindowQuery } from './Utilities/getWindowQuery';
import { getVersion } from './Utilities/getVersion';

function DataMapper(): ReactElement {
  const query = getWindowQuery();
  const initialPage = query.reopen ? 'mapper' : 'welcome';
  const DMVersion = getVersion(query.appProductVersion ?? '');
  const state = useFunState<DataMapperState>(init_DataMapperState(DMVersion, undefined, undefined, initialPage));
  const setPage = state.prop('page').set;
  const setOverlay = state.prop('overlayDisplay').set;
  const { overlayDisplay, page } = state.get();

  useEffect(() => {
    if (initialPage === 'mapper') {
      postActionTelemetry('open_from_menu', 'mapper');
      postMessage('get_mappable_fields', {});
    }
    const messageHandler = MessageHandler(state);
    window.addEventListener('message', messageHandler);
    return (): void => window.removeEventListener('message', messageHandler);
  }, []);

  if (window.location.pathname.search('/test') === 0) {
    return <Harness />;
  }

  return (
    <>
      <button className="focusTrap" onFocus={focusTrap('beginning')}></button>
      <Overlays state={state} />
      {route(page)}
      <button className="focusTrap" onFocus={focusTrap('end')}></button>
    </>
  );

  function route(view: ViewType): ReactElement {
    switch (view) {
      case 'welcome': {
        postMessage('resize_dialog', { shouldDock: true });
        return (
          <DockedDialog
            buttons={[
              {
                buttonName: Messages.addYourDataButtonNotNow(),
                buttonFunc: (): void => setOverlay({ kind: 'reopen' }),
              },
              {
                buttonName: Messages.addYourDataButtonStart(),
                buttonFunc: (): void => {
                  postActionTelemetry('get_started', 'add_your_data');
                  postMessage('get_mappable_fields', {});
                },
              },
            ]}
            header={Messages.addYourDataHeader()}
            body={Messages.addYourDataBodyText()}
            hero="welcome"
            focusable={overlayDisplay.kind === 'none'}
          />
        );
      }
      // TODO split this into two separate "pages"
      case 'mapper': {
        const { dataSource } = state.get();
        const isDataMappable: boolean = !!dataSource.tables.length;
        return isDataMappable ? <MapData state={state} /> : <ConnectToData state={state} />;
      }
      case 'select_datasource': {
        return <ConnectToData state={state} />;
      }
      case 'data_connected':
        return (
          <DockedDialog
            buttons={[
              {
                buttonName: Messages.dataConnectedButton(),
                buttonFunc: (): void => {
                  setPage('setup_data_source');
                  state.prop('tablesRequested').set(true);
                },
              },
            ]}
            header={Messages.dataConnectedHeader()}
            body={Messages.dataConnectedBodyText()}
            hero="data_connected"
            focusable={overlayDisplay.kind === 'none'}
          />
        );
      case 'setup_data_source':
        return (
          <DockedDialog
            buttons={[
              {
                buttonName: Messages.setupDataSourceButton(),
                buttonFunc: (): void => {
                  setPage('mapper');
                  postMessage('resize_dialog', { shouldDock: false });
                  postMessage('get_data_source_fields', {
                    dataSourceName: state.prop('dataSource').get().datasourceName,
                  });
                },
                disabled: !Object.keys(state.prop('dataSourceFields').get()).length,
              },
            ]}
            header={Messages.setupDataSourceHeader()}
            body={Messages.setupDataSourceBodyText()}
            hero="setup_data_source"
            focusable={overlayDisplay.kind === 'none'}
          />
        );
      case 'replacement_complete':
        return (
          <DockedDialog
            buttons={[
              {
                buttonName: Messages.replacementCompleteButtonEdit(),
                buttonFunc: (): void => {
                  setPage('mapper');
                  postActionTelemetry('make_changes_replace_data', 'replacement_complete');
                  postMessage('resize_dialog', { shouldDock: false });
                },
              },
              {
                buttonName: Messages.replacementCompleteButtonDone(),
                buttonFunc: (): void => setOverlay({ kind: 'close' }),
              },
            ]}
            header={Messages.replacementCompleteHeader()}
            body={Messages.replacementCompleteBodyText()}
            hero="replacement_complete"
            focusable={overlayDisplay.kind === 'none'}
          />
        );
    }
  }
}

export default DataMapper;
