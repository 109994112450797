type KeyValuepairs = Record<string, string>;
export const getWindowQuery = (): KeyValuepairs => {
  const result: KeyValuepairs = {};
  if (typeof window !== 'undefined') {
    const parts = (window.location.search ?? '').replace(/^\?/, '').split(/\?|&/g);
    parts.reduce((res, pair) => {
      const [key, value] = pair.split('=');
      res[key] = value ?? true;
      return res;
    }, result);
  }
  return result;
};

const parameterize = (key: string, value: string | boolean): string =>
  typeof value === 'string' ? `${key}=${value}` : key;

export const queryRecordToString = (record: KeyValuepairs): string =>
  Object.keys(record)
    .map((key) => parameterize(key, record[key]))
    .join('&');
