import React, { ReactElement, useRef } from 'react';
import { AcceleratorField } from '../Types/AcceleratorField';
import styles from './AcceleratorFieldComponent.module.css';
import { Tooltip, TooltipPosition } from '@tableau/widgets';
import ToolTipText from './ToolTipText';
import DataTypeIcon from './DataTypeIcon';
import classNames from 'classnames';

interface AcceleratorFieldComponentProps {
  field: AcceleratorField;
  needsData?: boolean;
  focusable?: boolean;
}

const iconSize = 14;

const AcceleratorFieldComponent = ({
  field,
  needsData,
  focusable = true,
}: AcceleratorFieldComponentProps): ReactElement => {
  const { fieldName, geographicRole, dataType, status } = field;
  const div = useRef<HTMLDivElement>(null);
  const fieldClassName = classNames(styles.acceleratorField, styles.field, dataType, geographicRole, status, {
    Geographic: geographicRole,
    acceleratorFieldNeedsData: needsData,
  });

  const fieldElement = (
    <div className={styles.fieldContainer}>
      <div ref={div} className={fieldClassName}>
        <DataTypeIcon name={geographicRole ? 'Geographic' : field.dataType} size={iconSize} />
        {fieldName}
      </div>
    </div>
  );

  return focusable ? (
    <Tooltip zIndex={5} getContent={(): ReactElement => <ToolTipText field={field} />} position={TooltipPosition.Right}>
      {fieldElement}
    </Tooltip>
  ) : (
    fieldElement
  );
};

export default AcceleratorFieldComponent;
