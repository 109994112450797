import { ExcludeBaseIcon as IconInvalid } from '@tableau/icons/ExcludeBaseIcon';
import { ArrowRightIcon as IconArrow } from '@tableau/icons/ArrowRightIcon';
import { NotificationSuccessIcon as IconLinked } from '@tableau/icons/NotificationSuccessIcon';
import React, { ReactElement } from 'react';
import { FieldStatus } from '../Types/FieldStatus';

const StatusIcon = ({ status }: { status: FieldStatus }): ReactElement => {
  switch (status) {
    case 'invalid':
      return <IconInvalid size={14} />;
    case 'pending':
    case 'mapped':
      return <IconLinked size={14} />;
    case 'unmapped':
    case 'locked': {
      return <IconArrow size={14} />;
    }
  }
};

export default StatusIcon;
