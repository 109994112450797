import { Tooltip, TooltipPosition } from '@tableau/widgets';
import classNames from 'classnames';
import React, { KeyboardEventHandler, ReactElement, useEffect, useRef } from 'react';
import { DataType } from '../Types/DataType';
import { Field } from '../Types/Field';
import styles from './DataSourceFieldComponent.module.css';
import DataTypeIcon from './DataTypeIcon';
import ToolTipText from './ToolTipText';

const iconSize = 14;

interface DataSourceFieldComponentProps {
  field: Field;
  dataType?: DataType;
  pick: () => void;
  ineligibleMessage: string;
  highlighted?: boolean;
  keyHandler: KeyboardEventHandler;
}

const DataSourceFieldComponent = ({
  field,
  dataType,
  pick,
  ineligibleMessage,
  highlighted = false,
  keyHandler,
}: DataSourceFieldComponentProps): ReactElement => {
  const thisButton = useRef<HTMLButtonElement>(null);
  const rect: { top: number; bottom: number } = { top: 0, bottom: 0 };

  useEffect((): void => {
    if (highlighted) {
      const button = thisButton.current;
      if (button) {
        button.scrollIntoView({ behavior: 'smooth', block: 'center' });
        if (button.getBoundingClientRect().top > 0 && button.getBoundingClientRect().bottom > 0) {
          rect.top = button.getBoundingClientRect().top;
          rect.bottom = button.getBoundingClientRect().bottom;
        }
      }
    }
  }, [highlighted]);

  return (
    <Tooltip
      zIndex={5}
      getContent={(): ReactElement => <ToolTipText field={field} disabledMessage={ineligibleMessage} />}
      position={TooltipPosition.Left}
    >
      <button
        ref={thisButton}
        onClick={pick}
        className={classNames(
          styles.dataSourceField,
          styles.field,
          dataType,
          highlighted && styles.highlighted,
          ineligibleMessage ? styles.ineligible : 'eligible',
        )}
        onKeyDown={keyHandler}
        tabIndex={-1}
      >
        <div>
          <DataTypeIcon name={field.geographicRole ? 'Geographic' : field.dataType} size={iconSize} />
          <span>{field.fieldName}</span>
        </div>
      </button>
    </Tooltip>
  );
};
export default DataSourceFieldComponent;
