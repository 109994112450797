import { FunState, merge } from '@fun-land/fun-state';
import DataMapperState from '../DataMapperState';
import { saveSession } from './getSaveableState';
import { postMessage } from '../FrameMessaging';

export const selectDataSource =
  (state: FunState<DataMapperState>) =>
  (datasourceName: string): void => {
    const { targetDatasources, dataSource } = state.get();
    const newSource = targetDatasources.find((source) => source.datasourceName === datasourceName);
    const newDataSource = {
      datasourceName,
      caption: newSource?.dataSourceCaption ?? '',
      tables: [],
      fieldsSetup: false,
    };
    if (
      dataSource.datasourceName &&
      newDataSource.datasourceName !== state.prop('dataSource').prop('datasourceName').get()
    ) {
      merge(state)({
        overlayDisplay: {
          kind: 'change_datasource',
          newDataSource,
        },
      });
      saveSession(state.get());
    } else {
      merge(state)({ dataSource: newDataSource, page: 'mapper' });
      saveSession(state.get());
      postMessage('get_data_source_fields', { dataSourceName: datasourceName });
    }
  };
