/* eslint-disable @typescript-eslint/consistent-type-assertions */
import React from 'react';
import ReactDOM from 'react-dom/client';
import DataMapper from './DataMapper';
import { getUserLang } from './Utilities/localize';

const loadScript = (url: string): Promise<unknown> =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = url;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });

// import reportWebVitals from './reportWebVitals';

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <DataMapper />
  </React.StrictMode>,
);

function loadLocalizedApp(): void {
  const userLang = getUserLang();
  Promise.all([`/messages.${userLang}.js`, `/formatters-and-parsers.${userLang}.js`].map(loadScript))
    .then(() =>
      root.render(
        <React.StrictMode>
          <DataMapper />
        </React.StrictMode>,
      ),
    )
    .catch(alert);
}
loadLocalizedApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
