import { MessageLocale, supportedMessageLocales } from '@tableau/locales';

declare global {
  interface Navigator {
    userLanguage?: string;
  }
}

const normalizeLocale = (userLanguage: string): string => {
  const langMap = {
    ja: 'ja_JP',
    ko: 'ko_KR',
    ga: 'ga_IE',
    'fr-ca': 'fr_CA',
    fr: 'fr_FR',
    it: 'it_IT',
    es: 'es_ES',
    de: 'de_DE',
    pt: 'pt_BR',
    'zh-hk': 'zh_TW',
    'zh-tw': 'zh_TW',
    zh: 'zh_CN',
    'en-us': 'en_US',
    'en-uk': 'en_UK',
    sv: 'sv_SE',
    th: 'th_TH',
  };
  for (const [prefix, val] of Object.entries(langMap)) {
    if (userLanguage.toLowerCase().startsWith(prefix)) return val;
  }

  const _userLanguage = userLanguage.replace('-', '_');
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  if (supportedMessageLocales.includes(_userLanguage as MessageLocale)) {
    return _userLanguage;
  }
  return 'en_US'; // Fallback to en_US
};

export const getUserLang = (): string => {
  const lang = normalizeLocale(
    new URLSearchParams(window.location.search).get('lang') ??
      sessionStorage.getItem('userLanguage') ??
      navigator.language ??
      navigator.userLanguage,
  );
  sessionStorage.setItem('userLanguage', lang);
  return lang;
};
