import { AcceleratorField } from '../../Types/AcceleratorField';

const mockAcceleratorFields: AcceleratorField[] = [
  {
    status: 'unmapped',
    fieldName: 'Director',
    fullyQualifiedName: 'Director',
    dataType: 'String',
    description: 'This is a dummy field',
    error: '',
  },
  {
    status: 'unmapped',
    fieldName: 'State',
    fullyQualifiedName: 'State',
    dataType: 'String',
    geographicRole: 'State',
    description: 'This is a dummy field',
    error: '',
  },
  {
    status: 'unmapped',
    fieldName: 'Country',
    fullyQualifiedName: 'Country',
    dataType: 'String',
    geographicRole: '[Country].[Name]',
    description: 'This is a dummy field',
    error: '',
  },
  {
    status: 'unmapped',
    fieldName: 'Order Date',
    fullyQualifiedName: 'OrderDate',
    dataType: 'DateTime',
    description: 'This is a dummy field',
    error: '',
  },
  {
    status: 'unmapped',
    fieldName: 'Accelerator Field 1 a a . aa a . a a aa aa a a a a a a a',
    fullyQualifiedName: 'Accelerator Field 1',
    dataType: 'String',
    description: 'This is a dummy field with a a a a a a a aa a a a a a . a a a a aa a a',
    error: '',
  },
  {
    status: 'unmapped',
    fieldName: 'Accelerator Field 2',
    fullyQualifiedName: 'Accelerator Field 2',
    dataType: 'Boolean',
    description: 'This is a dummy field',
    error: '',
  },
  {
    status: 'unmapped',
    fieldName: 'Accelerator Field 3',
    fullyQualifiedName: 'Accelerator Field 3',
    dataType: 'Date',
    description: 'This is a dummy field',
    error: '',
  },
  {
    status: 'unmapped',
    fieldName: 'Accelerator Field 6',
    fullyQualifiedName: 'Accelerator Field 6',
    dataType: 'Integer',
    description: 'This is a dummy field',
    error: '',
  },
  {
    status: 'unmapped',
    fieldName: 'Accelerator Field 7',
    fullyQualifiedName: 'Accelerator Field 7',
    dataType: 'Real',
    description: 'This is a dummy field',
    error: '',
  },
  {
    status: 'unmapped',
    fieldName: 'Accelerator Field 8',
    fullyQualifiedName: 'Accelerator Field 8',
    dataType: 'String',
    description: 'This is a dummy field',
    error: '',
  },
  {
    status: 'unmapped',
    fieldName: 'Accelerator Field 9',
    fullyQualifiedName: 'Accelerator Field 9',
    dataType: 'String',
    description: 'This is a dummy field',
    error: '',
  },
  {
    status: 'unmapped',
    fieldName: 'Accelerator Field 10',
    fullyQualifiedName: 'Accelerator Field 10',
    dataType: 'Integer',
    description: 'This is a dummy field',
    error: '',
  },
  {
    status: 'unmapped',
    fieldName: 'Accelerator Field 12',
    fullyQualifiedName: 'Accelerator Field 12',
    dataType: 'String',
    description: 'This is a dummy field',
    error: '',
  },
];

export default mockAcceleratorFields;
