import { AcceleratorField, ReturnFields } from './Types/AcceleratorField';
import { TargetDataSource } from './Types/DataSource';
import { SaveableState } from './Types/SaveableState';
import { Table } from './Types/Table';

interface FrameMessage<Name extends string, T> {
  readonly event: Name;
  readonly payload: T;
}

export type MessageToTableau =
  | FrameMessage<'invoke_choose_data_source', undefined>
  | FrameMessage<'get_mappable_fields', {}>
  | FrameMessage<'get_data_source_fields', { dataSourceName: string }>
  | FrameMessage<'apply_data_mapping', ReturnFields>
  | FrameMessage<'close_dialog', { shouldDisable: boolean }>
  | FrameMessage<'resize_dialog', { shouldDock: boolean }>
  | FrameMessage<'store_current_state', SaveableState>
  // `any` because `reportEvent` has dumb type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | FrameMessage<'telemetry_event', { eventType: string; eventPropertyNameValuePairs: any[] }>;

export type DataSourceSelectedMessage = FrameMessage<'data_source_selected', { name: string; caption?: string }>;

// Messages sent from Tableau to this UI
export type MessageFromTableau =
  | DataSourceSelectedMessage
  | FrameMessage<
      'mappable_fields',
      {
        acceleratorReferencedFields: AcceleratorField[];
        datasourceName: string;
        datasourceCaption?: string;
        mapperState?: SaveableState;
        targetDataSources?: TargetDataSource[];
      }
    >
  | FrameMessage<'data_source_fields', Table[] | { dataSourceName: string; tables: Table[] }>
  | FrameMessage<'should_prompt_user_before_closing', void>
  | FrameMessage<'mapping_completed', {}>;

export const postMessage = <Msg extends MessageToTableau>(event: Msg['event'], payload: Msg['payload']): void => {
  const message = { event, payload };
  window.parent?.postMessage(message, '*');
};

interface Flags {
  [k: string]: string;
}
const serializeFeatureFlags = (flags: Flags): string =>
  Object.entries(flags)
    .map(([k, v]) => `${k}=${String(v)}`)
    .join(',');

export const logTelemetry = (
  eventType: string,
  flags: Flags,
  payload: { [k: string]: number | string | boolean },
): void =>
  postMessage('telemetry_event', {
    eventType,
    eventPropertyNameValuePairs: Object.entries({ ...payload, feature_flags: serializeFeatureFlags(flags) }).flat(),
  });
