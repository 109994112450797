import type { RefObject } from 'react';
import { useEffect, useRef } from 'react';

export const useClickOutside = <ContainerElementType extends HTMLElement>(
  callback: EventListener,
): RefObject<ContainerElementType> => {
  const callbackRef = useRef(callback); // initialize mutable ref, which stores callback
  const innerRef = useRef<ContainerElementType>(null); // returned to client, who marks "border" element

  /*  // update cb on each render, so second useEffect has access to current value
  useEffect((): void => {
    callbackRef.current = callback;
  });
*/
  useEffect(() => {
    const handleClick: EventListener = (e): void => {
      if (
        innerRef.current &&
        callbackRef.current &&
        e.target &&
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        !innerRef.current.contains(e.target as ContainerElementType)
      )
        callbackRef.current(e);
    };
    document.addEventListener('click', handleClick);
    return (): void => document.removeEventListener('click', handleClick);
  }, []); // no dependencies -> stable click listener

  return innerRef; // convenience for client (doesn't need to init ref himself)
};
