/* eslint-disable complexity */
import { ClockBaseIcon } from '@tableau/icons/ClockBaseIcon';
import { NumberBaseIcon } from '@tableau/icons/NumberBaseIcon';
import { CalendarBaseIcon } from '@tableau/icons/CalendarBaseIcon';
import { CalendarClockIcon } from '@tableau/icons/CalendarClockIcon';
import { TextStringIcon } from '@tableau/icons/TextStringIcon';
import { BooleanBaseIcon } from '@tableau/icons/BooleanBaseIcon';
import { GlobeBaseIcon } from '@tableau/icons/GlobeBaseIcon';
import React, { ReactElement } from 'react';
import { DataType } from '../Types/DataType';

type sizes = 12 | 14 | 16 | 18;

type IconName = DataType | 'Geographic';

interface DataTypeIconProps {
  name: IconName;
  size: sizes;
  altText?: string;
  className?: string;
}

const Icons = {
  Time: ClockBaseIcon,
  Real: NumberBaseIcon,
  Integer: NumberBaseIcon,
  Date: CalendarBaseIcon,
  DateTime: CalendarClockIcon,
  String: TextStringIcon,
  Boolean: BooleanBaseIcon,
  Geographic: GlobeBaseIcon,
} as const;

const isIconName = (dataType: IconName): dataType is keyof typeof Icons => dataType in Icons;

const DataTypeIcon = ({ name, size, altText, className }: DataTypeIconProps): ReactElement | null => {
  if (isIconName(name)) {
    const IconElement = Icons[name];
    return <IconElement size={size} alt={altText ?? name} className={className} />;
  }
  return null;
};

export default DataTypeIcon;
