import { FunState } from '@fun-land/fun-state';
import DataMapperState, { tablesToDataSourceFields, DataSourceFields } from '../DataMapperState';
import { Field } from '../Types/Field';
import { AcceleratorField } from '../Types/AcceleratorField';
import { Messages } from '../codegen/Localize';
import { postMessage } from '../FrameMessaging';
import { Table } from '../Types/Table';
import { tableSorter } from './sorters';
import autoMap from './autoMap';

const getError = (field: AcceleratorField, datasourceField: Field): string => {
  if (!datasourceField) return Messages.noMatchingFields();
  if (datasourceField.dataType !== field.dataType) return Messages.error_type();
  if (datasourceField.geographicRole !== field.geographicRole) return Messages.error_geo_role();
  return '';
};

const updateAcceleratorFields = (
  dataSourceFields: DataSourceFields,
  sourceFields: AcceleratorField[],
): AcceleratorField[] =>
  sourceFields.map((field) => {
    if (!field.newTargetFieldMapping) return field;
    const error = getError(field, dataSourceFields[field.newTargetFieldMapping]);
    const status = error ? 'invalid' : field.status === 'invalid' ? 'pending' : field.status;
    return { ...field, status, error };
  });

const updateDataSource = (state: FunState<DataMapperState>, tables: Table[]): void => {
  const { dataSource, acceleratorFields: sourceFields } = state.get();
  const isFirstBatch = !dataSource.fieldsSetup;
  const dataSourceFields = tablesToDataSourceFields(tables.sort(tableSorter));
  const premap = autoMap(
    sourceFields,
    tables.flatMap((table) => table.fields),
  );
  state.mod((s) => ({
    ...s,
    acceleratorFields: updateAcceleratorFields(dataSourceFields, premap),
    dataSourceFields,
    dataSource: { ...dataSource, tables, fieldsSetup: true },
  }));
  if (isFirstBatch && tables.length && state.prop('acceleratorFields').get().length) {
    state.prop('page').set('mapper');
    postMessage('resize_dialog', { shouldDock: false });
  }
};

export default updateDataSource;
