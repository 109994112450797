import React, { ReactElement } from 'react';
import styles from './Overlay.module.css';

interface OverlayProps {
  headerText: string;
  bodyText: string;
  footer: ReactElement;
}

export const Overlay = (props: OverlayProps): ReactElement => (
  <div className={styles.overlay}>
    <h2 className={styles.overlayHeader}>{props.headerText}</h2>
    <p className={styles.overlayText}>{props.bodyText}</p>
    <div className={styles.overlayFooter}>{props.footer}</div>
  </div>
);
