import { Acc, flow, mergeInto, set } from '@fun-land/accessor';
import { FunState, merge } from '@fun-land/fun-state';
import { postMessage } from './FrameMessaging';
import { AcceleratorField } from './Types/AcceleratorField';
import { DataSource, TargetDataSource } from './Types/DataSource';
import { Field } from './Types/Field';
import { OverlayDisplay } from './Types/OverlayDisplay';
import { SaveableState } from './Types/SaveableState';
import { Table } from './Types/Table';
import { ViewType } from './Types/ViewTypes';
import { saveSession } from './Utilities/getSaveableState';

type DMVersions = number;

export type DataSourceFields = Record<string, Field>;

interface DataMapperState {
  page: ViewType;
  targetDatasources: TargetDataSource[];
  acceleratorFields: AcceleratorField[];
  dataSource: DataSource;
  activeTargetField: AcceleratorField | null;
  collapsed: boolean;
  filterTerm: string;
  highlightedField: string;
  zone: number;
  dataSourceFields: DataSourceFields;
  tablesRequested: boolean;
  acceleratorDataSourceName: string;
  acceleratorDataSourceCaption: string;
  overlayDisplay: OverlayDisplay;
  version: DMVersions;
}

export const tablesToDataSourceFields = (fields: Table[]): DataSourceFields =>
  Acc<Table[]>()
    .all()
    .prop('fields')
    .all()
    .query(fields)
    .reduce<DataSourceFields>((acc, field) => {
      acc[field.fullyQualifiedName] = field;
      return acc;
    }, {});

export const init_DataMapperState = (
  version: DMVersions,
  sourceFields: AcceleratorField[] = [],
  dataSource: DataSource = { datasourceName: '', tables: [], fieldsSetup: false, caption: '' },
  initialPage: ViewType = 'welcome',
): DataMapperState => ({
  targetDatasources: [],
  page: initialPage,
  acceleratorFields: sourceFields,
  dataSource,
  activeTargetField: null,
  collapsed: false,
  filterTerm: '',
  highlightedField: '',
  zone: 0,
  dataSourceFields: tablesToDataSourceFields(dataSource.tables),
  tablesRequested: false,
  overlayDisplay: { kind: 'none' },
  acceleratorDataSourceName: '',
  acceleratorDataSourceCaption: '',
  version,
});

export const clearUIActivity = set(Acc<DataMapperState>().prop('activeTargetField'))(null);

export const clearAcceleratorFieldBindings = Acc<DataMapperState>()
  .prop('acceleratorFields')
  .all()
  .mod(mergeInto<AcceleratorField>({ newTargetFieldMapping: '', status: 'unmapped', error: '' }));

export default DataMapperState;

const updateFieldsFromMap = (mapping: Record<string, string>): ((struct: DataMapperState) => DataMapperState) =>
  Acc<DataMapperState>()
    .prop('acceleratorFields')
    .all()
    .mod(
      (field): AcceleratorField =>
        mapping[field.fullyQualifiedName]
          ? {
              ...field,
              newTargetFieldMapping: mapping[field.fullyQualifiedName],
              status: 'pending',
            }
          : field,
    );

export const restorePreviousSession = (state: FunState<DataMapperState>, savedState: SaveableState): void => {
  merge(state.prop('dataSource'))({
    datasourceName: savedState.selectedTargetDataSource.name,
    caption: savedState.selectedTargetDataSource.caption,
  });
  postMessage('get_data_source_fields', {
    dataSourceName: savedState.selectedTargetDataSource.name,
  });
  const interimMap = savedState.targetFields.fields.reduce((maps: Record<string, string>, map) => {
    maps[map.sourceFullyQualifiedFieldName] = map.targetFullyQualifiedFieldName;
    return maps;
  }, {});
  state.mod(flow(mergeInto<DataMapperState>({ overlayDisplay: { kind: 'none' } }), updateFieldsFromMap(interimMap)));
  saveSession(state.get());
};
