import { logTelemetry } from '../FrameMessaging';
import { TelemetryUserAction } from '../Types/TelemetryEvent';

export const postActionTelemetry = (
  action_name: TelemetryUserAction,
  page_name: string,
  total_fields: number = 0,
  unmapped_fields: number = 0,
): void => {
  logTelemetry(
    'accelerator_data_mapper_action',
    {},
    {
      action_name,
      page_name,
      number_of_fields_mapped: total_fields - unmapped_fields,
      total_fields,
    },
  );
};
