/* eslint-disable complexity */
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import mockAcceleratorFields from '../Assets/MockData/mockAcceleratorFields';
import { MessageToTableau } from '../FrameMessaging';
import './Harness.css';
import { AcceleratorField } from '../Types/AcceleratorField';
import { Table } from '../Types/Table';
import Button from '../Components/Button';
import { SaveableState } from '../Types/SaveableState';
import { getWindowQuery, queryRecordToString } from '../Utilities/getWindowQuery';
import { getVersion } from '../Utilities/getVersion';
import { dataSources } from '../Assets/MockData/mockDataSource';

const Harness = (): ReactElement => {
  const [version, setVersion] = useState<string>('2023.1.0');
  const [query, setQuery] = useState<Record<string, string | boolean>>(getWindowQuery());
  const iframeUrl = `${window.location.href.replace('test', '')}?${queryRecordToString({
    ...query,
    appProductVersion: version,
  })}`;
  const dockedSize = { x: 344, y: 424 };
  const expandedSize = { x: 600, y: 464 };
  const [size, setSize] = useState<{ x: number; y: number }>(dockedSize);
  const [savedState, setSavedState] = useState<SaveableState>();
  const [sendPopulatedTables, setSendPopulatedTables] = useState(true);

  const [open, setOpen] = useState<boolean>(true);
  const frame = useRef<HTMLIFrameElement>(null);
  const post = (message: {
    event: string;
    payload:
      | never[]
      | Table[]
      | Array<Table | undefined>
      | { displayName: string; name: string; tables: never[] }
      | {}
      | { acceleratorReferencedFields: AcceleratorField[]; datasourceName: string }
      | {}
      | null;
  }): void => {
    const target = frame.current?.contentWindow;
    if (target) {
      target.postMessage(message, '*');
    }
  };

  const [datasource, setDatasource] = useState<
    'fullMock' | 'modded' | 'loner' | 'empty' | 'anotherMockDataSource' | 'addLater'
  >('empty');

  const sendTables = (datasourceName: string): void => {
    if (dataSources[datasourceName]) {
      post({
        event: 'data_source_fields',
        payload: sendPopulatedTables ? dataSources[datasourceName].tables : [],
      });
      setSendPopulatedTables(true);
    }
  };

  const chooseDataSource = (): void => {
    alert("At this point, you'd be choosing a data source outside of the mapper. Click okay to continue.");
    const { datasourceName: name, caption } = dataSources[datasource];
    post({
      event: 'data_source_selected',
      payload: { name, caption, tables: [] },
    });
  };
  const handleMessage = ({ data }: MessageEvent<MessageToTableau>): void => {
    console.log(data.event, data.payload);
    switch (data.event) {
      case 'invoke_choose_data_source': {
        chooseDataSource();
        break;
      }
      case 'apply_data_mapping': {
        alert('Mapping sent.');
        post({ event: 'mapping_completed', payload: {} });
        break;
      }
      case 'close_dialog': {
        setOpen(false);
        break;
      }
      case 'get_mappable_fields': {
        const payload: Record<string, unknown> = {
          acceleratorReferencedFields: mockAcceleratorFields,
          datasourceName: 'Sample Accelerator',
          datasourceCaption: 'Sample Accelerator',
          mapperState: {},
        };
        if (savedState) payload.mapperState = savedState;
        if (getVersion(version) > 1)
          payload.targetDataSources = Object.keys(dataSources)
            .filter((key) => key !== 'addLater')
            .map((source) => ({
              datasourceName: dataSources[source].datasourceName,
              dataSourceCaption: dataSources[source].caption,
            }));
        post({
          event: 'mappable_fields',
          payload,
        });
        break;
      }
      case 'get_data_source_fields': {
        sendTables(data.payload.dataSourceName);
        break;
      }
      case 'resize_dialog': {
        setSize(data.payload.shouldDock ? dockedSize : expandedSize);
        break;
      }
      case 'store_current_state': {
        console.log('Saving state\n', { state: data.payload });
        setSavedState(data.payload);
      }
    }
  };

  const reopen = (): void => {
    setQuery({ ...query, reopen: true });
    setOpen(true);
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return (): void => {
      window.removeEventListener('message', handleMessage);
    };
  });

  return (
    <div className="outer">
      <div className="testHarness">
        <h1>
          Data Mapper UI Test Harness{' '}
          <select onChange={(e): void => setVersion(e.target.value ?? '0.0.0')}>
            <option value="2023.1.0">Version 1</option>
            <option value="2023.2.5">Version 2</option>
          </select>
        </h1>
        <div>
          <h3>Send messages to iframe</h3>
          {open ? (
            <>
              <div className="datasourcer">
                <label>Choose Datasource </label>
                <div>
                  <label>
                    <input
                      name="dataSource"
                      type="Radio"
                      value="fullMock"
                      checked={datasource === 'fullMock'}
                      onChange={(): void => setDatasource('fullMock')}
                    />
                    Original
                  </label>
                  <label>
                    <input
                      name="dataSource"
                      type="Radio"
                      value="modded"
                      checked={datasource === 'modded'}
                      onChange={(): void => setDatasource('modded')}
                    />
                    Modified
                  </label>
                  <label>
                    <input
                      name="dataSource"
                      type="Radio"
                      value="loner"
                      checked={datasource === 'loner'}
                      onChange={(): void => setDatasource('loner')}
                    />
                    One Table
                  </label>
                  <label>
                    <input
                      name="dataSource"
                      type="Radio"
                      value="anotherMockDataSource"
                      checked={datasource === 'anotherMockDataSource'}
                      onChange={(): void => setDatasource('anotherMockDataSource')}
                    />
                    Secondary
                  </label>
                  <label>
                    <input
                      name="dataSource"
                      type="Radio"
                      value="empty"
                      checked={datasource === 'empty'}
                      onChange={(): void => setDatasource('empty')}
                    />
                    Empty
                  </label>
                  <label>
                    <input
                      name="dataSource"
                      type="Radio"
                      value="empty"
                      checked={datasource === 'addLater'}
                      onChange={(): void => setDatasource('addLater')}
                    />
                    Add Later
                  </label>
                  <label>
                    <input
                      name="populateTables"
                      type="checkbox"
                      checked={sendPopulatedTables}
                      onChange={(e): void => setSendPopulatedTables(e.target.checked)}
                    />
                    Send populated tables
                  </label>
                </div>
              </div>
              <div className="harnessActions">
                <Button buttonType="secondary" onClick={(): void => sendTables(datasource)}>
                  Send Data Source Fields
                </Button>
                <Button buttonType="secondary" onClick={chooseDataSource}>
                  Send Data Source Connected Message
                </Button>
                <Button
                  buttonType="secondary"
                  onClick={(): void => post({ event: 'should_prompt_user_before_closing', payload: {} })}
                >
                  Send close message
                </Button>
              </div>
            </>
          ) : (
            <button onClick={reopen}>Reopen</button>
          )}
        </div>
        {open && (
          <iframe id="data-mapper-iframe" ref={frame} src={iframeUrl} title="dmui" height={size.y} width={size.x} />
        )}
      </div>
      <div className="stateDisplay">
        <h2>Current Saved State</h2>
        <div>{JSON.stringify(savedState, undefined, 2)}</div>
      </div>
    </div>
  );
};

export default Harness;
