/* eslint-disable complexity */
import DataMapperState from '../DataMapperState';
import { DataSourceSelectedMessage, MessageFromTableau, postMessage } from '../FrameMessaging';
import { FunState, merge } from '@fun-land/fun-state';
import { filter } from '@fun-land/accessor';
import updateDataSource from '../Utilities/updateDataSource';
import { matchesStatus } from '../Types/AcceleratorField';
import { DataSource } from '../Types/DataSource';
import { isValidSavedSession } from '../Types/SaveableState';
import { saveSession } from '../Utilities/getSaveableState';
import addTargetDatasource from '../Utilities/addTargetDatasource';
import { postActionTelemetry } from '../Utilities/postActionTelemetry';

const dataSourceMessageToDataSource = ({ payload }: DataSourceSelectedMessage): DataSource => ({
  datasourceName: payload.name,
  caption: payload.caption ?? '',
  tables: [],
  fieldsSetup: false,
});

const MessageHandler =
  (state: FunState<DataMapperState>) =>
  ({ data }: MessageEvent<MessageFromTableau>): void => {
    const { datasourceName: dataSourceName } = state.prop('dataSource').get();
    const page = state.prop('page').get();
    switch (data.event) {
      case 'data_source_selected': {
        if (dataSourceName && dataSourceName !== data.payload.name) {
          state
            .prop('overlayDisplay')
            .set({ kind: 'change_datasource', newDataSource: dataSourceMessageToDataSource(data) });
          break;
        }
        merge(state)({
          dataSource: dataSourceMessageToDataSource(data),
          page: page === 'mapper' ? 'data_connected' : page,
        });
        addTargetDatasource(
          { datasourceName: data.payload.name, dataSourceCaption: data.payload.caption ?? '' },
          state,
        );
        saveSession(state.get());
        if (page === 'mapper') postMessage('get_data_source_fields', { dataSourceName: data.payload.name });
        postMessage('resize_dialog', { shouldDock: true });
        break;
      }
      case 'mappable_fields': {
        merge(state)({
          acceleratorFields: data.payload.acceleratorReferencedFields.map((field) => ({
            ...field,
            status: 'unmapped',
          })),
          acceleratorDataSourceName: data.payload.datasourceName,
          acceleratorDataSourceCaption: data.payload.datasourceCaption ?? '',
          targetDatasources: data.payload.targetDataSources ?? [],
          page: 'mapper',
          overlayDisplay:
            !data.payload.mapperState || !isValidSavedSession(data.payload.mapperState)
              ? { kind: 'none' }
              : // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                { kind: 'restorePreviousSession', mapperState: data.payload.mapperState },
        });
        postMessage('resize_dialog', { shouldDock: false });
        if (data.payload.targetDataSources?.length) {
          postActionTelemetry('connected_data_sources', 'connect_to_data');
        }
        break;
      }
      case 'data_source_fields': {
        if (state.prop('overlayDisplay').prop('kind').get() === 'change_datasource') break;
        if ('tables' in data.payload) {
          const { tables } = data.payload;
          if (tables[0] && data.payload.dataSourceName === dataSourceName) {
            updateDataSource(state, tables);
          }
          break;
        }
        if (!data.payload[0] || data.payload[0]?.fields[0].fullyQualifiedName.search(`[${dataSourceName}].`) === 1) {
          updateDataSource(state, data.payload);
        }
        break;
      }
      case 'mapping_completed': {
        state
          .prop('acceleratorFields')
          .focus(filter(matchesStatus('pending')))
          .mod((field) => ({ ...field, status: 'mapped', fullyQualifiedName: field.newTargetFieldMapping ?? '' }));
        merge(state)({
          page: 'replacement_complete',
          acceleratorDataSourceName: dataSourceName,
        });
        postMessage('resize_dialog', { shouldDock: true });
        break;
      }
      case 'should_prompt_user_before_closing': {
        state.prop('overlayDisplay').set({ kind: 'close' });
      }
    }
  };

export default MessageHandler;
