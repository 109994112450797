const processParagraph = (html: string): string[] => {
  const fragment = document.createElement('div');
  fragment.innerHTML = html;
  if (fragment.firstChild) fragment.removeChild(fragment.firstChild);
  return [fragment.innerText ?? fragment.textContent];
};

const processTable = (html: string): string[] => {
  const fragment = document.createElement('div');
  fragment.innerHTML = html;
  return Array.from(fragment.querySelectorAll('tr')).map((row) => row.innerText ?? fragment.textContent);
};

const processSampleData = (html?: string | string[]): string[] =>
  Array.isArray(html) ? html : html ? (html.search('<table>') > -1 ? processTable(html) : processParagraph(html)) : [];

export default processSampleData;
