import classNames from 'classnames';
import React, { ForwardedRef, MutableRefObject, ReactElement, useEffect, useState } from 'react';
import styles from './ScrollContainer.module.css';

// stupid because of deprecated old bad function form of react refs
const isRefObj = <T extends HTMLElement>(ref: ForwardedRef<T>): ref is MutableRefObject<T | null> =>
  !!ref && typeof ref !== 'function';

export const ScrollContainer = React.forwardRef<HTMLDivElement, { children: ReactElement; className?: string }>(
  (props, ref) => {
    const [atTop, setAtTop] = useState(true);
    const [atBottom, setAtBottom] = useState(false);
    useEffect(() => {
      const onScroll = (): void => {
        if (isRefObj(ref) && ref.current) {
          const { scrollTop, offsetHeight, scrollHeight } = ref.current;
          setAtTop(scrollTop === 0);
          setAtBottom(scrollTop + offsetHeight === scrollHeight);
        }
      };
      isRefObj(ref) && ref?.current?.addEventListener('scroll', onScroll);
      return (): void => {
        isRefObj(ref) && ref?.current?.removeEventListener('scroll', onScroll);
      };
    }, []);
    return (
      <div className={classNames(styles.wrap, { [styles.atTop]: atTop, [styles.atBottom]: atBottom }, props.className)}>
        <div ref={ref} className={styles.ScrollContainer}>
          {props.children}
        </div>
      </div>
    );
  },
);
