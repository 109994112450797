import React, { ReactElement } from 'react';
import styles from './DataSourceFieldComponent.module.css';

interface TableSectionProps {
  tableName: string;
  children: ReactElement[];
}

const TableSection = ({ tableName, children }: TableSectionProps): ReactElement => (
  <>
    {children.length ? (
      <button className={`${styles.dataSourceField} ${styles.field} ${styles.tableName}`} key={tableName}>
        {tableName.replace(/[[\]']+/g, '')}
      </button>
    ) : null}
    {children}
  </>
);

export default TableSection;
