import React, { ReactElement } from 'react';
import { Messages } from '../codegen/Localize';
import { Field } from '../Types/Field';
import Alert from './Alert';
import styles from './ToolTip.module.css';
import DataTypeIcon from './DataTypeIcon';
import { DataType, GeographicRole } from '../Types/DataType';
import processSampleData from '../Utilities/processSampleData';

interface TootlTipProps {
  field: Field;
  disabledMessage?: string;
}

const formatDatatypeString = (dataType: DataType, geographicRole: GeographicRole | undefined): string => {
  const isMessageKey = (key: string): key is keyof typeof Messages => key in Messages;
  const roleKey = geographicRole?.replace(/[[\].]/g, '');
  const dataTypeString = Messages[dataType] ? Messages[dataType]() : dataType;
  return roleKey && isMessageKey(roleKey)
    ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      (Messages[roleKey] as () => string)() + ' (' + dataTypeString + ')'
    : dataTypeString;
};

const ToolTipText = ({ field, disabledMessage }: TootlTipProps): ReactElement => {
  const { fieldName, dataType, geographicRole, description, sampleValues: SampleValuesText } = field;
  const sampleValues = processSampleData(SampleValuesText);

  return (
    <div className={styles.toolTipText}>
      <div className={styles.fieldName}>{fieldName}</div>
      {disabledMessage && <Alert text={disabledMessage} />}
      <div className={`${styles.header} ${styles.typeHeader}`}>{Messages.dataType()}</div>
      <div className={styles.dataType}>
        <DataTypeIcon name={geographicRole ? 'Geographic' : dataType} size={14} />
        {formatDatatypeString(dataType, geographicRole)}
      </div>
      {description && (
        <>
          <h4 className={styles.header}>{Messages.description()}</h4>
          <div className={styles.text}>{description}</div>
        </>
      )}
      {!!sampleValues.length && (
        <>
          <h4 className={styles.header}>{Messages.sampleValues()}</h4>
          <ul className={styles.sampleValues}>
            {sampleValues.map(
              (value: string): ReactElement => (
                <li className={styles.sampleValue} key={value}>
                  {value}
                </li>
              ),
            )}
          </ul>
        </>
      )}
    </div>
  );
};

export default ToolTipText;
